<template>
    <div>
        <div class="loader" v-if="loading" key="loading">Now loading...</div>
        <transition name="loading" mode="out-in">
            <div class="arptable" v-if="!loading">
                <transition name="strans" mode="out-in">
                    <div v-if="!updating">
                        <div class="tablecaption">
                            <span class="tablecaptionvalue">{{ caption }}</span>
                        </div>
                        <table class="salestarget">
                            <thead>
                                <tr>
                                    <th rowspan="2">部署</th>
                                    <th
                                        v-for="(d, ix) in monthlist"
                                        :key="ix"
                                        colspan="5"
                                    >
                                        <span class="year"
                                            >{{ d.substring(0, 4) }} 年</span
                                        >
                                        <span class="month"
                                            >{{ formatYm(d) }} 月</span
                                        >
                                    </th>
                                    <th class="" colspan="5">合計</th>
                                </tr>
                                <tr>
                                    <template
                                        v-for="ix in monthlist.length + 1"
                                    >
                                        <th :key="'a' + ix">売上</th>
                                        <th :key="'b' + ix">外注費</th>
                                        <th :key="'c' + ix">仕入</th>
                                        <th :key="'d' + ix">支払手数料</th>
                                        <th :key="'e' + ix">内部売上</th>
                                    </template>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(did, ix) in mydeptids" :key="ix">
                                    <th class="dept_name">
                                        <div
                                            class="item"
                                            v-for="(dpt, ixd) in mydepts[did]"
                                            :key="ixd"
                                            style="display: inline-block"
                                        >
                                            <div
                                                class="code"
                                                style="font-size: 0.5rem"
                                            >
                                                {{ dpt.department_code }}
                                            </div>
                                            {{ dpt.department_name_short }}
                                            <span
                                                v-if="
                                                    ixd <
                                                        mydepts[did].length - 1
                                                "
                                                >,</span
                                            >
                                        </div>
                                    </th>
                                    <template v-for="(m, mi) in monthlist">
                                        <td
                                            :key="'a' + mi"
                                            class="amount slsamt"
                                        >
                                            {{
                                                getAmount(
                                                    did,
                                                    m,
                                                    "sales_amount"
                                                ) | amount
                                            }}
                                        </td>
                                        <td :key="'b' + mi" class="amount">
                                            {{
                                                getAmount(
                                                    did,
                                                    m,
                                                    "outsourcing_cost"
                                                ) | amount
                                            }}
                                        </td>
                                        <td :key="'c' + mi" class="amount">
                                            {{
                                                getAmount(
                                                    did,
                                                    m,
                                                    "purchase_amount"
                                                ) | amount
                                            }}
                                        </td>
                                        <td :key="'d' + mi" class="amount">
                                            {{
                                                getAmount(
                                                    did,
                                                    m,
                                                    "commission_paid"
                                                ) | amount
                                            }}
                                        </td>
                                        <td :key="'e' + mi" class="amount bene">
                                            {{ getBenAmt(did, m) | amount }}
                                        </td>
                                    </template>
                                    <td class="amount">
                                        {{
                                            getAmount(
                                                did,
                                                "total",
                                                "sales_amount"
                                            ) | amount
                                        }}
                                    </td>
                                    <td class="amount">
                                        {{
                                            getAmount(
                                                did,
                                                "total",
                                                "outsourcing_cost"
                                            ) | amount
                                        }}
                                    </td>
                                    <td class="amount">
                                        {{
                                            getAmount(
                                                did,
                                                "total",
                                                "purchase_amount"
                                            ) | amount
                                        }}
                                    </td>
                                    <td class="amount">
                                        {{
                                            getAmount(
                                                did,
                                                "total",
                                                "commission_paid"
                                            ) | amount
                                        }}
                                    </td>
                                    <td class="amount">
                                        {{ getBenAmt(did, "total") | amount }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th></th>
                                    <template v-for="(m, mi) in monthlist">
                                        <td
                                            :key="'a' + mi"
                                            class="amount slsamt"
                                        >
                                            {{
                                                getTotalAmount(
                                                    m,
                                                    "sales_amount"
                                                ) | amount
                                            }}
                                        </td>
                                        <td :key="'b' + mi" class="amount">
                                            {{
                                                getTotalAmount(
                                                    m,
                                                    "outsourcing_cost"
                                                ) | amount
                                            }}
                                        </td>
                                        <td :key="'c' + mi" class="amount">
                                            {{
                                                getTotalAmount(
                                                    m,
                                                    "purchase_amount"
                                                ) | amount
                                            }}
                                        </td>
                                        <td :key="'d' + mi" class="amount">
                                            {{
                                                getTotalAmount(
                                                    m,
                                                    "commission_paid"
                                                ) | amount
                                            }}
                                        </td>
                                        <td :key="'e' + mi" class="amount bene">
                                            {{ getTotalBenAmt(m) | amount }}
                                        </td>
                                    </template>
                                    <td class="amount">
                                        {{
                                            getTotalAmount(
                                                "total",
                                                "sales_amount"
                                            ) | amount
                                        }}
                                    </td>
                                    <td class="amount">
                                        {{
                                            getTotalAmount(
                                                "total",
                                                "outsourcing_cost"
                                            ) | amount
                                        }}
                                    </td>
                                    <td class="amount">
                                        {{
                                            getTotalAmount(
                                                "total",
                                                "purchase_amount"
                                            ) | amount
                                        }}
                                    </td>
                                    <td class="amount">
                                        {{
                                            getTotalAmount(
                                                "total",
                                                "commission_paid"
                                            ) | amount
                                        }}
                                    </td>
                                    <td class="amount">
                                        {{ getTotalBenAmt("total") | amount }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
// import DateUtils from "@/DateUtils";

export default {
    name: "SummaryMonthTable",
    props: {
        caption: String,
        fiscalyear: [String, Number],
        // startdate: Date,
        // enddate: Date,
        monthlist: Array,
        refreshcount: Number,
        params: Object,
        orderstatus: Array,
        mydepts: Object,
        mydeptids: Array
    },
    data: function() {
        return {
            months: [],
            datalist: {},
            totals: {},
            loading: true,
            updating: false,
            datakeys: []
        };
    },
    watch: {
        refreshcount: async function() {
            this.loaddata();
        }
    },
    created: async function() {
        if (this.fiscalyear) {
            this.loaddata();
        }
        // this.depts = Array.from(this.deptlist);
    },
    methods: {
        // initMonths: function() {
        //     let smonth = this.startdate.getMonth();
        //     let emonth = this.enddate.getMonth();
        //     let syear = this.startdate.getFullYear();
        //     let eyear = this.enddate.getFullYear();

        //     let dyear = eyear - syear;
        //     let dmonth = emonth - smonth;
        //     if (dmonth < 0) {
        //         dmonth = 12 + dmonth;
        //         dyear--;
        //     }
        //     let mcount = dyear * 12 + dmonth + 1;

        //     let ms = [];
        //     let sdate = new Date(this.startdate.getTime());
        //     sdate.setDate(1);
        //     for (let i = 0; i < mcount; i++) {
        //         let sd = new Date(sdate.getTime());
        //         sd.setMonth(sd.getMonth() + i);
        //         ms.push(DateUtils.toYm6(sd));
        //     }
        //     return ms;
        // },

        formatYm: function(ym) {
            let ret = ym.substring(4);
            if (ret.startsWith("0")) {
                ret = ret.substring(1);
            }
            return ret;
        },
        dispSts: function() {
            let ret = "";
            for (let item of this.orderstatus) {
                ret += "," + item;
            }
            if (ret != "") {
                ret = ret.substring(1);
            }
            return ret;
        },
        loaddata: async function() {
            this.loading = true;
            // this.months = this.initMonths();
            let params = {
                // period: DateUtils.toRequestRangeDate(
                //     this.startdate,
                //     this.enddate
                // )
            };
            this.datalist = {};
            params["sts"] = this.orderstatus;
            if (this.params) {
                for (let k in this.params) {
                    params[k] = this.params[k];
                }
            }

            let createCols = function() {
                return {
                    sales_amount: 0,
                    costofsales_amount: 0,
                    outsourcing_cost: 0,
                    purchase_amount: 0,
                    commission_paid: 0
                };
            };

            let getColValue = function(obj, key) {
                let ret = obj[key];
                if (!ret) {
                    ret = 0;
                }
                return new Number(ret);
            };

            let addCols = function(dist, org) {
                dist["sales_amount"] += getColValue(org, "sales_amount");
                dist["costofsales_amount"] += getColValue(
                    org,
                    "costofsales_amount"
                );
                dist["outsourcing_cost"] += getColValue(
                    org,
                    "outsourcing_cost"
                );
                dist["purchase_amount"] += getColValue(org, "purchase_amount");
                dist["commission_paid"] += getColValue(org, "commission_paid");
            };

            return this.readDataToArray(
                this.makeurl("summarymonth", this.fiscalyear, params),
                list => {
                    const ddict = {};
                    for (const d of list) {
                        let did = d.department_id;
                        let ymdict = ddict[did];
                        if (!ymdict) {
                            ymdict = {};
                            ddict[did] = ymdict;
                        }
                        ymdict[d.year_month] = d;
                    }
                    this.totals["total"] = createCols();

                    for (const did in ddict) {
                        let yms = ddict[did];
                        let tym = createCols();
                        for (const ym in yms) {
                            let obj = yms[ym];
                            addCols(tym, obj);

                            let vym = this.totals[ym];
                            if (!vym) {
                                vym = createCols();
                                this.totals[ym] = vym;
                            }
                            addCols(vym, obj);
                        }
                        yms["total"] = tym;

                        addCols(this.totals["total"], tym);
                    }

                    this.datalist = ddict;
                    this.loading = false;
                }
            );
        },

        getAmount: function(did, ym, colname) {
            let ret = 0;
            let row = this.datalist;
            if (row && row[did]) {
                let dobj = row[did];
                if (dobj && dobj[ym]) {
                    let ymobj = dobj[ym];
                    if (ymobj && ymobj[colname]) {
                        ret = new Number(ymobj[colname]);
                    }
                }
            }
            return ret;
        },
        getBenAmt: function(did, ym) {
            let a = this.getAmount(did, ym, "sales_amount");
            let b = this.getAmount(did, ym, "costofsales_amount");
            return a - b;
        },
        getTotalAmount: function(ym, colname) {
            let ret = 0;
            let dobj = this.totals;
            if (dobj && dobj[ym]) {
                let ymobj = dobj[ym];
                if (ymobj && ymobj[colname]) {
                    ret = new Number(ymobj[colname]);
                }
            }
            return ret;
        },
        getTotalBenAmt: function(ym) {
            let a = this.getTotalAmount(ym, "sales_amount");
            let b = this.getTotalAmount(ym, "costofsales_amount");
            return a - b;
        },
        download: function() {
            let params = this.makeParams();

            let filename = "売上達成率";
            for (const p in params) {
                let v = params[p];
                filename += "_";
                if (v) {
                    if (v instanceof Array) {
                        for (let c of v) {
                            filename += c;
                        }
                    } else {
                        filename += v;
                    }
                }
            }
            filename += ".xlsx";
            this.downloadData(this.makeurl("stgt", "xlsx", params), filename);
        }
    },
    updated: function() {
        this.$nextTick(() => (this.updating = false));
    }
};
</script>

<style scoped>
.arptable {
    position: relative;
    display: inline-block;
}

.arptable table {
    position: relative;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
}
.arptable table tr > * {
    border-style: solid none none solid;
    border-width: 1px;
    background-color: var(--main-context-bg-color);
}
.arptable table td {
    text-align: right;
    font-size: 1rem;
    vertical-align: middle;
}
.arptable table th {
    text-align: right;
    font-size: 0.9rem;
    vertical-align: middle;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
    /* filter: grayscale(50%) brightness(98%); */
}
.arptable table tbody tr.branchDept {
    /* filter: brightness(95%); */
}
.arptable table tbody tr.branchDept > * {
    border-top-style: dashed;
    /* font-size: 75%; */
    /* letter-spacing: 1.75px; */
    filter: hue-rotate(0deg) grayscale(50%) brightness(90%);
}
.arptable table tbody th > .child::before {
    content: " - ";
    padding-right: 0.5rem;
    opacity: 0.25;
}
/* .arptable table tbody tr:last-child th > .child::before {
    content: "└";
} */
/* .arptable table tbody tr.branchDept > * {
    border-top-style: dotted;
    border-bottom-style: dotted;
} */
.tablecaption {
    display: block;
    position: sticky;
    top: 70px;
    left: 0;
    background-color: var(--main-context-bg-color);
    z-index: 4;
    padding: 10px;
    text-align: left;
    margin: 0;
    width: 100%; /* calc(100vw - 18px); */
}
.tablecaptionvalue {
    position: sticky;
    left: 10px;
}

.arptable table thead tr:nth-child(1) > * {
    position: sticky;
    top: 108px;
    /* background-color: #eee; */
    z-index: 2;
}
.arptable table thead tr:nth-child(2) > * {
    position: sticky;
    top: 135px;
    /* background-color: #eee; */
    z-index: 2;
}
.arptable table thead tr > th {
    border-bottom-style: solid;
    vertical-align: bottom;
    text-align: center;
}

.arptable thead tr:first-child > *:first-child {
    position: sticky;
    top: 108px;
    left: 0;
    /* background-color: #eee; */
    border-right-style: solid;
    border-right-width: 1px;
    z-index: 3;
}
.arptable thead tr:first-child > *:last-child {
    border-right-style: solid;
    border-right-width: 1px;
    /* position: sticky;
    right: 0; */
}
.arptable table tbody tr.branchDept > *:first-child {
    /* background-color: #edd; */
}
.arptable tbody tr > *:first-child {
    position: sticky;
    left: 0;
    border-right-width: 1px;
    border-right-style: solid;
    /* background-color: #eee; */
    text-align: left;
    z-index: 2;
}

.arptable tbody tr > *:last-child {
    /* position: sticky;
    right: 0; */
    border-right-width: 1px;
    border-right-style: solid;
}

.arptable tbody tr:nth-child(even) > td {
    /* filter: contrast(95%); */
    /* background-color: hsl(333, 100%, 97%); */
}

.arptable tbody tr:nth-child(2n + 1) > td {
    background: var(--odd-bg-color);
}

.arptable table .slsamt {
    border-left-width: 2px;
}

.arptable table .bene {
    border-right-width: 2px;
}

.arptable table .coltotal {
    border-left-style: double;
    border-left-width: 4px;
    position: sticky;
    right: 0;
}

.arptable table tfoot tr > * {
    position: sticky;
    bottom: 0;
    /* background-color: #eee; */
    border-top-style: double;
    border-top-width: 6px;
    z-index: 2;
}
.arptable tfoot tr > *:first-child {
    position: sticky;
    bottom: 0;
    left: 0;
    border-right-width: 1px;
    border-right-style: solid;
    /* background-color: #eee; */
    z-index: 3;
}
.arptable tfoot tr > * {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
.arptable tfoot tr > *:last-child {
    border-right-width: 1px;
    border-right-style: solid;
    /* position: sticky;
    right: 0; */
}
.arptable tfoot tr td {
    /* filter: grayscale(80%) brightness(98%); */
}
.arptable tfoot tr td.detail {
    /* filter: brightness(95%); */
}

.arptable .detail {
    /* font-size: 0.75rem; */
    /* opacity: 0.65; */
    /* filter: brightness(95%); */
    /* filter: brightness(95%); */
    font-size: 75%;
    letter-spacing: 1.25px;
}
.arptable th.detail {
    /* opacity: 0.65; */
    /* filter: brightness(95%); */
    /* filter: grayscale(10%) brightness(95%); */
    font-size: 75%;
}

/* .arptable .achievement-rate {
    border-right-style: double;
    border-right-width: 6px;
} */
.arptable .achievement-rate + * {
    /* border-left-style: double;
    border-left-width: 6px; */
}

.arptable .amount {
    min-width: 8em;
    width: 8em;
}
.arptable .rate {
    min-width: 4em;
    font-size: 1.1em;
}
.arptable .value {
    height: 1rem;
}
.arptable .logdata {
    height: 1rem;
    opacity: 0.5;
}
.arptable .logdiff {
    height: 1rem;
    opacity: 0.5;
}

/* --- */
.year {
    font-size: 75%;
}
/* --- */

.strans-enter-active,
.strans-leave-active {
    /* animation: sizeScale 0.5s ease reverse; */
    transition: 0.25s;
}

.strans-enter,
.strans-leave-to {
    /* animation: sizeScale ease 0.5s; */
    opacity: 0;
}
@keyframes sizeScale {
    100% {
        /* width: 0px; */
        opacity: 0;
    }
}
/* ------------ */
.controlbox {
    padding: 10px;
}
</style>
