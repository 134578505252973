<template>
    <div class="arp">
        <div class="header1 page-header">
            <h1 class="title">月別集計表</h1>
            <div style="display: inline-block">
                <OpSelectFiscalyear
                    v-model="fiscalyear"
                    label="会計年度"
                    @input="changedYear"
                    :company="getCompany()"
                    required
                    hideheader
                />
            </div>

            <button @click="download">
                <IconBase
                    iconName="Excel"
                    width="24"
                    height="24"
                    visibleName
                    v-slot="{ viewbox }"
                    ><IconDownload @viewbox="viewbox"
                /></IconBase>
            </button>

            <div style="display: block; width: 100%">
                <div class="anchors">
                    <a
                        v-for="(d, ix) in stspattern"
                        :key="ix"
                        :href="'#smm' + ix"
                        v-smooth-scroll="{ offset: -68 }"
                        class="tableanch"
                    >
                        {{ formatlink(d) }}
                    </a>
                </div>
            </div>
        </div>

        <div class="body">
            <div
                class="tableframe"
                v-for="(d, ix) in stspattern"
                :key="ix"
                :id="'smm' + ix"
            >
                <SummaryMonthTable
                    :caption="formatCaption(d)"
                    :fiscalyear="fiscalyear.key"
                    :monthlist="monthList"
                    :refreshcount="refreshCount"
                    :orderstatus="d"
                    :mydepts="mydepts"
                    :mydeptids="mydeptids"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SummaryMonthTable from "@/views/summarymonth/SummaryMonthTable.vue";
import OpSelectFiscalyear from "@/components/OpSelectFiscalyear.vue";
// import OpRadioGroup from "@/components/OpRadioGroup.vue";
// import OpMultiSelector from "@/components/OpMultiSelector.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
import DateUtils from "@/DateUtils";

export default {
    name: "SummaryMonth",
    components: {
        SummaryMonthTable,
        OpSelectFiscalyear,
        // OpRadioGroup,
        IconBase,
        IconDownload
        // ,
        // OpMultiSelector
    },
    props: {},
    data: function() {
        let comp = this.getCompany();
        let fh = DateUtils.getFiscalYearAndHalfsignToday(comp);
        let dts = DateUtils.getFiscalRangeDate(comp, fh.fiscalyear, 0);
        let mlist = DateUtils.getYearMonthList(dts.start, dts.end);
        // let today = new Date();
        return {
            fiscalyear: { key: fh.fiscalyear },
            monthList: mlist,
            // startdate: dts.start,
            // enddate: dts.end,
            refreshCount: 1,
            dpstatus: [],
            stspattern: [
                ["A", "B"],
                ["A", "B", "C"],
                ["A", "B", "C", "D"]
            ],
            mydepts: {},
            mydeptids: []
        };
    },
    watch: {
        dpstatus: function() {
            this.refreshCount++;
        }
    },
    methods: {
        formatCaption: function(sts) {
            let ret = "";
            let y = this.fiscalyear.key;
            if (!y) y = this.fiscalyear;
            let ey = this.getCompany().establishment_year;
            ret += DateUtils.formatFiscalYear(y, ey);

            let sss = "";
            for (let item of sts) {
                sss += "," + item;
            }
            if (sss != "") {
                sss = sss.substring(1);
            }
            ret += " " + sss;
            return ret;
        },
        formatlink: function(sts, podiv) {
            let ret = "";
            if (podiv) {
                let div = this.divs["po"][podiv];
                if (div != null) {
                    ret += " ( " + div.contentlong + " ) ";
                }
            }

            let sss = "";
            for (let item of sts) {
                sss += "" + item;
            }

            ret += sss;

            return ret;
        },
        changedYear: async function() {
            let dts = DateUtils.getFiscalRangeDate(
                this.getCompany(),
                this.fiscalyear.key,
                0
            );
            // this.startdate = dts.start;
            // this.enddate = dts.end;

            this.monthList = DateUtils.getYearMonthList(dts.start, dts.end);
            await this.loaddepts();
            this.refreshCount++;
        },
        download: function() {
            // let params = this.makeParams();

            let filename = "月別売上集計表";
            filename += "-" + this.fiscalyear.key + "年度";

            let sts = "";
            for (let s of this.stspattern) {
                sts += ":" + s;
            }
            if (sts != "") {
                sts = sts.substring(1);
            }

            let params = {
                sts: sts
            };

            filename += "_" + DateUtils.toString8();
            filename += ".xlsx";

            this.downloadData(
                this.makeurl(
                    "summarymonth",
                    "xlsx",
                    this.fiscalyear.key,
                    params
                ),
                filename
            );
        },
        initdeptlist: function(deptlist) {
            let pobj = {};
            let ids = [];
            for (let dept of deptlist) {
                const did = dept.department_id;
                if (dept.department_div == 1) {
                    let obj = {
                        department_id: dept.department_id,
                        department_code: dept.department_code,
                        department_name_short: dept.department_name_short
                    };
                    let ddd = pobj[did];
                    if (ddd) {
                        pobj[did].push(obj);
                    } else {
                        pobj[did] = [obj];
                        ids.push(did);
                    }
                }
            }
            this.mydeptids = ids;
            return pobj;
        },
        loaddepts: function(func) {
            this.loading = true;
            let params = {
                year: this.fiscalyear.key
            };
            return this.readDataToArray(this.makeurl("dept", params), list => {
                this.mydepts = this.initdeptlist(list);
                console.log(this.mydepts);
                if (func) {
                    func();
                }
            });
        }
    },
    created: async function() {
        await this.loaddivs("po", "pmt", "dep");
        await this.loaddepts();
    }
};
</script>

<style scoped>
/* .control-box {
    position: sticky;
    bottom: 0;
    border-top: 1px solid #999;
    padding: 10px 0;
    margin-top: 10px;
    text-align: center;
} */
.anchors {
    font-size: 0.8rem;
    padding: 5px;
    text-align: center;
}
.tableanch {
    padding: 0 10px 0 0;
    display: inline-block;
}

.arp .header1 {
    height: 80px;
}

.arp .header2 {
    display: flex;
    justify-items: center;
    justify-content: center;

    width: 100%;
    text-align: center;
    vertical-align: top;
    z-index: 0;
    padding: 0;
}
.arp {
    position: relative;
    padding-left: 10px;
}
.arp .body {
    position: relative;

    padding: 60px 0 0 0;
    z-index: 0;
    background-color: var(--main-context-bg-color);
    margin: 0;
}
.arp .tableframe {
    position: relative;
    padding: 10px 0;
    /* overflow-x: scroll; */
    /* height: calc(100vh - 160px); */
    /* overflow-y: auto; */
}
.tableframe {
    text-align: center;
}
.achievement-rate {
    border-right-style: double;
}
.dept2 {
    border-left-style: double;
    border-left-width: 3px;
}
.soposts {
    padding: 0px 10px;
}
.soposts > div {
    padding: 0px 0px;
    /* transform: scale(0.75); */
}

.logcondition > div {
    padding: 0px 10px;
    display: inline-block;
}
.control-box {
    position: sticky;
    bottom: 0px;
    z-index: 1;
    display: grid;

    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > *:first-child {
    justify-self: left;
}
.control-box > *:last-child {
    justify-self: right;
}

.page-header {
    position: fixed;
    left: 0px;
    width: calc(100vw - 10px);
}

/* --- */
.compareSetting {
    min-height: 300px;
}
.compareSetting > fieldset {
    margin: 0 0.5rem 1rem 1rem;
    padding: 0.5rem 0 1rem 2rem;
}
.compareSetting > fieldset > legend {
    margin-left: -3.25rem;
    padding: 0.5rem;
}
/* --- */

@media (max-width: 1199px) {
    .mpc {
        display: inherit;
    }
    .mpc-inline-block {
        display: inline-block;
    }
    .xpc {
        display: none;
    }
}
@media (min-width: 1200px) {
    .mpc {
        display: none;
    }
    .xpc {
        display: inherit;
    }
}
</style>
